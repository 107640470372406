<template>
  <div v-if="userModel">
    <v-breadcrumbs :items="crumbs"></v-breadcrumbs>
    <base-loader :isLoading="isLoading"></base-loader>
    <v-row>
      <v-col md="6">
        <v-card>
          <v-card-text>
            <h4 class="text-md-h4 primary--text mb-5">
              User Details
            </h4>
            <v-simple-table v-if="$route.params.id != 0">
              <tbody>
                <tr>
                  <td>
                    First Name
                  </td>
                  <td>
                    {{ user.firstName ? userModel.firstName : "" }}
                  </td>
                </tr>
                <tr>
                  <td>
                    Last Name
                  </td>
                  <td>
                    {{ userModel.lastName }}
                  </td>
                </tr>
                <tr>
                  <td>
                    Email
                  </td>
                  <td>
                    {{ userModel.email ? userModel.email : "" }}
                  </td>
                </tr>
                <tr>
                  <td>
                    Telephone Number
                  </td>
                  <td>
                    {{ userModel.telNumber ? userModel.telNumber : "" }}
                  </td>
                </tr>
                <tr>
                  <td>
                    Existing Products Chosen During Registration
                  </td>
                  <td>
                    {{
                      userModel.existingProducts
                        ? userModel.existingProducts
                        : ""
                    }}
                  </td>
                </tr>
                <tr>
                  <td>
                    Products Chosen During Registration That They Are Interested
                    In
                  </td>
                  <td>
                    {{
                      userModel.productsInterestedIn
                        ? userModel.productsInterestedIn
                        : ""
                    }}
                  </td>
                </tr>
              </tbody>
            </v-simple-table>

            <v-btn
              v-if="$route.params.id == 0"
              color="primary"
              :to="`${$route.path}/edit`"
              class="mr-2"
              >Create a New User</v-btn
            >
            <v-btn
              v-else
              color="primary"
              :to="`${$route.path}/edit`"
              class="mr-2"
              >Edit</v-btn
            >
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { User } from "@/models/User";
import { gql } from "apollo-boost";
export default {
  data() {
    return {
      isEdit: false,
      isLoading: false,
      userModel: new User(),
      me: "",
      search: null,
      crumbs: [
        {
          text: "Clients",
          disabled: false,
          href: "/consultant/users",
        },
        {
          text: "Client Details",
          disabled: true,
          href: `/consultant/user/${this.$route.params.id}`,
        },
      ],
    };
  },
  created() {},
  apollo: {
    me: gql`
      query {
        me {
          id
        }
      }
    `,
    user: {
      query: gql`
        query User($id: ID!) {
          user(id: $id) {
            id
            firstName
            lastName
            email
            telNumber
            existingProducts
            productsInterestedIn
          }
        }
      `,
      fetchPolicy: "network-only",
      skip() {
        return !this.$route.params.id || this.$route.params.id == 0;
      },
      variables() {
        const self = this;
        return {
          id: self.$route.params.id,
        };
      },
      watchLoading(isLoading) {
        this.isLoading = isLoading;
      },
      result(response, key) {
        console.log("got data");
        this.isLoading = false;
        this.userModel.id = this.user.id;
        this.userModel.firstName = this.user.firstName;
        this.userModel.lastName = this.user.lastName;
        this.userModel.email = this.user.email;
        this.userModel.telNumber = this.user.telNumber;
        this.userModel.existingProducts = this.user.existingProducts;
        this.userModel.productsInterestedIn = this.user.productsInterestedIn;
      },
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped></style>
